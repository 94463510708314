import React, { useEffect } from "react"
import Styles from "./RowCarousel.module.scss"

const RowCarousel = ({ rows, setSelectedRow, selectedRow }) => {
  useEffect(() => {
    if (selectedRow) {
      const rowToView = document.getElementById(selectedRow)
      console.log(selectedRow, rowToView)
      // Scroll the element into the center of the viewport
      if (rowToView) {
        rowToView.scrollIntoView({
          behavior: "auto", // You can use "auto" for instant scrolling
          block: "center",
          inline: "center",
        })
      }
    }
  }, [selectedRow, rows])

  const handleRowClick = (row) => {
    setSelectedRow(row)
  }

  return (
    <div className={Styles["carousel"]}>
      {rows.map((rowData, index) => (
        <div
          key={index}
          id={rowData.row}
          className={`${Styles["row"]} ${
            rowData.row === selectedRow ? Styles["selectedRow"] : ""
          }${rowData.classNames.reduce((text, className, index) => {
            if (!className) return text
            return `${text} ${Styles[className]}`
          }, "")}`}
          onClick={() => handleRowClick(rowData.row)}
        >
          {rowData.row}
        </div>
      ))}
    </div>
  )
}

export default RowCarousel
