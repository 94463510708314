const yardNames = {
    OGDEN_PIC_A_PART: "PIC-A-PART OGDEN",
    OGDEN_TEAR_A_PART: "TEAR-A-PART OGDEN",
    SLC_PIC_N_PULL: "PICK-n-PULL Salt Lake City",
    SLC_TEAR_A_PART: "TEAR A PART SLC",
    SLC_PULL_N_SAVE: "PULL-N-SAVE Salt Lake City",
    OREM_PIC_A_PART: "PIC-A-PART OREM",
    SV_PULL_N_SAVE: "PULL-N-SAVE Springville",
    IF_I_PULL: "IPULL-IDAHO-FALLS",
    IF_BIG_D: "BIGD-SALVAGE-IDAHO-FALLS",
    TF_PICK_A_PART: "TWIN-FALLS-PICK-A-PART",
    NAMPA_PICK_A_PART: "NAMPA-PICK-A-PART",
    CALDWELL_PICK_A_PART: "CALDWELL-PICK-A-PART",
    BOISE_PICK_A_PART: "BOISE-PICK-A-PART",
    GARDEN_CITY_PICK_A_PART: "GARDEN-CITY-PICK-A-PART",
    HENDERSON_PIC_A_PART: "HENDERSON-NEVADA-PIC-A-PART",
    LAS_VEGAS_PIC_A_PART: "LAS-VEGAS-NEVADA-PIC-A-PART"
}

export const displayedYardNames = {
    OGDEN_PIC_A_PART: "Ogden Pic a Part",
    OGDEN_TEAR_A_PART: "Ogden Tear a Part",
    OREM_PIC_A_PART: "Orem Pic a Part",
    SLC_PIC_N_PULL: "SLC Pick n Pull",
    SLC_PULL_N_SAVE: "SLC Pull n Save",
    SLC_TEAR_A_PART: "SLC Tear a Part",
    SV_PULL_N_SAVE: "SV Pull n Save",
    IF_I_PULL: "Idaho Falls iPull",
    IF_BIG_D: "Idaho Falls BigD",
    TF_PICK_A_PART: "Twin Falls Pick a Part",
    NAMPA_PICK_A_PART: "Nampa Pick a Part",
    CALDWELL_PICK_A_PART: "Caldwell Pick a Part",
    BOISE_PICK_A_PART: "Boise Pick a Part",
    GARDEN_CITY_PICK_A_PART: "Garden City Pick a Part",
    HENDERSON_PIC_A_PART: "Henderson Pic a Part",
    LAS_VEGAS_PIC_A_PART: "Las Vegas Pic a Part"
}

export default yardNames;
