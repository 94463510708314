import React, { useState, useContext, useEffect } from "react"
import Dropdown from "react-dropdown"
import "react-dropdown/style.css"
import Styles from "./CarList.module.scss"
import { storeContext } from "../../Store"
import CarDisplay from "./CarDisplay/CarDisplay"
import PartSearch from "./PartSearch/PartSearch"
import ModelSearch from "./ModelSearch/ModelSearch"

function CarList() {
  const storeData = useContext(storeContext)
  const {
    changeCurrentCar,
    cars,
    updateCar,
    currentCar,
    researchCar,
    setYardFilter,
    yardFilter,
    hotList,
    addToHotList,
    deleteDataBase,
    getCodesForPartSearch,
  } = storeData
  const [showPartSearchBar, togglePartSearchBar] = useState(false)
  const [showModelSearchBar, toggleModelSearchBar] = useState(false)
  const [scroll, setScroll] = useState()
  useEffect(() => {
    document.querySelector(".listOfCars").scrollTop = yardFilter.scroll
  }, [yardFilter, currentCar])

  const changeYardFilter = (key, value) => {
    setYardFilter((prev) => {
      return {
        ...prev,
        [key]: value,
      }
    })
  }

  const options = [
    { value: "PIC-A-PART OGDEN", label: "Pic a Part - Ogden" },
    { value: "TEAR-A-PART OGDEN", label: "Tear A Part - Ogden" },
    { value: "TEAR A PART SLC", label: "Tear A Part - Salt Lake City" },
    // {
    //   value: "PULL-N-SAVE Salt Lake City",
    //   label: "Pull N Save - Salt Lake City",
    // },
    // { value: "PULL-N-SAVE Springville", label: "Pull n Save - Springville" },
    {
      value: "PICK-n-PULL Salt Lake City",
      label: "Pick N Pull - Salt Lake City",
    },
    { value: "PIC-A-PART OREM", label: "Pic a Part - Orem" },
    { value: "IPULL-IDAHO-FALLS", label: "IPull - Idaho Falls" },
    { value: "BIGD-SALVAGE-IDAHO-FALLS", label: "BigD - Idaho Falls" },
    { value: "TWIN-FALLS-PICK-A-PART", label: "PickaPart - Twin Falls" },
    { value: "NAMPA-PICK-A-PART", label: "PickaPart - Nampa" },
    { value: "CALDWELL-PICK-A-PART", label: "PickaPart - Caldwell" },
    { value: "BOISE-PICK-A-PART", label: "PickaPart - Boise" },
    { value: "GARDEN-CITY-PICK-A-PART", label: "PickaPart - Garden City" },
    { value: "HENDERSON-NEVADA-PIC-A-PART", label: "PicaPart - Henderson" },
    { value: "LAS-VEGAS-NEVADA-PIC-A-PART", label: "PicaPart - Las Vegas" },
    { value: "all", label: "All Yards" },
  ]

  const allCars = () => {
    let tempCarList = []
    let yardValue = yardFilter.yard
    let sortValue = yardFilter.sortBy

    switch (yardValue) {
      case "Pic a Part - Ogden":
        tempCarList = cars.filter((x) => x.yard === "PIC-A-PART OGDEN")
        break
      case "Pic a Part - Orem":
        tempCarList = cars.filter((x) => x.yard === "PIC-A-PART OREM")
        break
      case "Tear A Part - Ogden":
        tempCarList = cars.filter(
          (x) => x.yard.toLowerCase() === "TEAR-A-PART OGDEN".toLowerCase()
        )
        break
      case "Tear A Part - Salt Lake City":
        tempCarList = cars.filter(
          (x) => x.yard.toLowerCase() === "TEAR A PART SLC".toLowerCase()
        )
        break
      case "Pull N Save - Salt Lake City":
        tempCarList = cars.filter(
          (x) => x.yard === "PULL-N-SAVE Salt Lake City"
        )
        break
      case "Pull n Save - Springville":
        tempCarList = cars.filter((x) => x.yard === "PULL-N-SAVE Springville")
        break
      case "Pick N Pull - Salt Lake City":
        tempCarList = cars.filter(
          (x) => x.yard === "PICK-n-PULL Salt Lake City"
        )
        break
      case "BigD - Idaho Falls":
        tempCarList = cars.filter(
          (x) => x.yard === "BIGD-SALVAGE-IDAHO-FALLS"
        )
        break
      case "IPull - Idaho Falls":
        tempCarList = cars.filter(
          (x) => x.yard === "IPULL-IDAHO-FALLS"
        )
        break
      case "PickaPart - Twin Falls":
        tempCarList = cars.filter(
          (x) => x.yard === "TWIN-FALLS-PICK-A-PART"
        )
        break
      case "PickaPart - Nampa":
        tempCarList = cars.filter(
          (x) => x.yard === "NAMPA-PICK-A-PART"
        )
        break
      case "PickaPart - Caldwell":
        tempCarList = cars.filter(
          (x) => x.yard === "CALDWELL-PICK-A-PART"
        )
        break
      case "PickaPart - Boise":
        tempCarList = cars.filter(
          (x) => x.yard === "BOISE-PICK-A-PART"
        )
        break
      case "PickaPart - Garden City":
        tempCarList = cars.filter(
          (x) => x.yard === "GARDEN-CITY-PICK-A-PART"
        )
        break
      case "PicaPart - Las Vegas":
        tempCarList = cars.filter(
          (x) => x.yard === "LAS-VEGAS-NEVADA-PIC-A-PART"
        )
        break
      case "PicaPart - Henderson":
        tempCarList = cars.filter(
          (x) => x.yard === "HENDERSON-NEVADA-PIC-A-PART"
        )
        break

      default:
        tempCarList = cars
    }

    if (yardFilter.hotList === true) {
      tempCarList = tempCarList.filter((x) => isInHotList(x, hotList))
    }

    if (yardFilter.recommended === true) {
      tempCarList = tempCarList.filter((x) => {
        return x.parts
          ? x.parts.classification
            ? x.parts.classification.classifiers.length !== 0
            : false
          : false
      })
    }

    if (yardFilter.modelSearch !== "") {
      tempCarList = tempCarList.filter((car) => {
        return car.model
          .toLowerCase()
          .includes(yardFilter.modelSearch.toLowerCase()) //THis needs to be modelSearch eventually
      })
    }
    if (yardFilter.carCodes.length !== 0) {
      tempCarList = tempCarList.filter((car) => {
        return yardFilter.carCodes.includes(car.code)
      })
    }

    switch (sortValue) {
      case "row":
        tempCarList.sort((a, b) => a.row - b.row)
        break
      case "year":
        tempCarList.sort((a, b) => {
          // Compare by year first (descending)
          if (b.year !== a.year) {
            return b.year - a.year
          }
          // If years are the same, compare make and model
          const makeModelComparison = (a.make + a.model).localeCompare(b.make + b.model)
          return makeModelComparison
        })
        break
      case "recent":
        tempCarList.sort((a, b) => a.days - b.days)
        break
      case "make":
        tempCarList.sort((a, b) => {
            // First, compare make and model
            let makeModelComparison = (a.make + a.model).localeCompare(b.make + b.model);
            
            // If make and model are the same, compare year
            if (makeModelComparison === 0) {
              return +b.year - +a.year
            } else {
              return makeModelComparison;
            }
          })
        break
      default:
        tempCarList.sort((a, b) => a.numParts - b.numParts)
    }

    return tempCarList.filter((x) => x.row != 300)
  }

  function isInHotList(car, hotlist) {
    const { year, make, model } = car
    return hotlist.some(
      (x) =>
        year >= x.yearFrom &&
        year <= x.yearTo &&
        make.toLowerCase() === x.make.toLowerCase() &&
        model.toLowerCase() === x.model.toLowerCase()
    )
  }

  let filteredCars = allCars()

  const listOfCars = filteredCars.map((x) => {
    return (
      <div key={x.vin} className={Styles.listItemWrapper}>
        <div>
          {x.seen && (
            <img
              onClick={(e) => {
                updateCar({ seen: false }, x.vin)
                changeYardFilter(
                  "scroll",
                  document.querySelector(".listOfCars").scrollTop
                )
              }}
              alt="eye"
              src={require("../../images/eye.png")}
            />
          )}
        </div>
        <h2
          style={
            currentCar.vin === x.vin
              ? { color: "#b64e29", textShadow: "0px 0px" }
              : { color: "rgb(41, 23, 16)" }
          }
          onClick={(e) => {
            changeCurrentCar(e.target.id)
            changeYardFilter(
              "scroll",
              document.querySelector(".listOfCars").scrollTop
            )
          }}
          id={x.vin}
        >
          {`${x.year} ${x.make} ${x.model}`}
        </h2>
        {/* <p>{yardFilter.partSearch !== "" ? numPartsFromPartSearch(x.parts) : x.numParts}</p>  */}
        <p>{x.row}</p>
      </div>
    )
  })

  function numPartsFromPartSearch(parts) {
    let count = 0
    for (let partCategory in parts) {
      for (let i = 0; i < parts[partCategory].length; i++) {
        if (
          parts[partCategory][i].title
            .toUpperCase()
            .includes(yardFilter.partSearch)
        )
          count++
      }
    }
    return count
  }

  return (
    <div className={Styles.wrapper}>
      <header>
        <div>
          <h3 onClick={() => {}}> Junk Yard</h3>
          <Dropdown
            className={Styles.dropdown}
            value={yardFilter.yard}
            options={options}
            onChange={(e) => changeYardFilter("yard", e.label)}
            placeholder="Select an option"
          />
        </div>
        <div>
          <p>{`${listOfCars.length}`}</p>
          <label
            style={yardFilter.carCodes.length > 0 ? { color: "#b64e29" } : {}}
            onClick={() => {
              if (yardFilter.carCodes.length > 0) {
                changeYardFilter("carCodes", [])
                changeYardFilter("partKeyword", "")
              } else {
                togglePartSearchBar(true)
              }
            }}
          >
            <i className="material-icons">search</i> PartSearch
          </label>
          <label
            style={yardFilter.modelSearch ? { color: "#b64e29" } : {}}
            onClick={() => {
              if (yardFilter.modelSearch) {
                changeYardFilter("modelSearch", "")
              } else {
                toggleModelSearchBar(true)
              }
            }}
          >
            <i className="material-icons">search</i> ModelSearch
          </label>
          <div className="spacer"></div>
          <label
            style={yardFilter.recommended ? { color: "#b64e29" } : {}}
            onClick={() => {
              changeYardFilter("recommended", !yardFilter.recommended)
            }}
          >
            <i className="material-icons">check_circle_outline</i>
          </label>
          <label
            style={yardFilter.hotList ? { color: "#b64e29" } : {}}
            onClick={() => changeYardFilter("hotList", !yardFilter.hotList)}
          >
            <i className="material-icons">whatshot</i>
          </label>
        </div>
      </header>
      <div className={`listOfCars ${Styles.listOfCars}`}>
        {listOfCars.length === 0 && (
          <div className={Styles["loadingWrapper"]}>
            <img
              className={Styles["loadingImg"]}
              src={require("../../images/loading.gif")}
            />
          </div>
        )}
        {listOfCars}
      </div>
      <div className={Styles.sort}>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <p
            style={yardFilter.sortBy === "row" ? { color: "#b64e29" } : {}}
            onClick={() => changeYardFilter("sortBy", "row")}
          >
            Row
          </p>
          <p
            style={yardFilter.sortBy === "make" ? { color: "#b64e29" } : {}}
            onClick={() => changeYardFilter("sortBy", "make")}
          >
            Make
          </p>
          <p
            style={yardFilter.sortBy === "year" ? { color: "#b64e29" } : {}}
            onClick={() => changeYardFilter("sortBy", "year")}
          >
            Year
          </p>
          <p
            style={yardFilter.sortBy === "recent" ? { color: "#b64e29" } : {}}
            onClick={() => changeYardFilter("sortBy", "recent")}
          >
            Recent
          </p>
        </div>
      </div>
      {showPartSearchBar && (
        <PartSearch
          {...{
            getCodesForPartSearch,
            setYardFilter,
            yardFilter,
            togglePartSearchBar,
          }}
        />
      )}
      {showModelSearchBar && (
        <ModelSearch {...{ setYardFilter, yardFilter, toggleModelSearchBar }} />
      )}
      <CarDisplay currentCar={currentCar} addToHotList={addToHotList} />
    </div>
  )
}

export default CarList
